import { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import api from 'api';
import { DateTime } from 'luxon';
import { useAlert } from 'common/alertContext';
import { MESSAGE_SUCCESS_CHANGES } from 'litterals';
import { useTranslation } from 'react-i18next';
import { createEvent } from 'ics';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs';
import { adjustDateWithTimezone } from 'utils/dateUtils';
var timezone = require('dayjs/plugin/timezone');
dayjs.extend(timezone);

export const useAddSession = (sessions, programId) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const newSessionNumber = sessions?.length > 0 ? Math.max(...sessions.map((session) => session.number)) + 1 : 1;

  const { handleSubmit, control, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: { name: null },
  });
  const mutation = useMutation(api.Sessions.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-programs']);
      closeHandler();
    },
  });

  const closeHandler = () => {
    reset();
    setOpen(false);
  };
  const openHandler = () => setOpen(true);
  const addSession = handleSubmit((data) =>
    mutation.mutate({
      programId: programId,
      done: false,
      isTripartite: false,
      number: sessions && Array.isArray(sessions) && sessions.length + 1,
      order: sessions && Array.isArray(sessions) && sessions.length + 1,
      name: data.name,
      date: data.date ? DateTime.fromISO(data.date).toSQLDate() : undefined,
    })
  );
  return {
    actions: { addSession, openHandler, closeHandler },
    state: { form: { control: control, state: formState }, nav: { formIsOpen: open } },
  };
};

export const useNextSession = (data) => {
  const [nextSession, setNextSession] = useState('');
  useEffect(() => {
    if (data) {
      const session = data.sessions.sort((a, b) => a.number - b.number).find((session) => !session.done);
      const next = session ? (session.date ? DateTime.fromISO(session.date).toFormat('DDDD') : 'TBD') : 'Done';
      setNextSession(next);
    }
  }, [data]);

  return nextSession;
};

const useSession = (session) => {
  const { setStateAlert } = useAlert();
  const { t } = useTranslation();

  const defaultValues = {
    comment: session.comment,
    done: session.done,
    date: DateTime.fromISO(session.date).setZone('local') || null,
    name: session.name || '',
    subtitle: session.subtitle || '',
    description: session.description || '',
    duration: 0,
  };

  const queryClient = useQueryClient();
  const { control, formState, reset } = useForm({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    reset({
      comment: session.comment,
      done: session.done,
      date: DateTime.fromISO(session.date).setZone('local') || null,
      name: session.name || '',
      subtitle: session.subtitle || '',
      description: session.description || '',
      duration: 0,
    });
  }, [session]);

  const mutation = useMutation(api.Sessions.edit, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-session']);
      // setStateAlert({ open: true, message: `${t(MESSAGE_SUCCESS_CHANGES)}` });
    },
  });
  const { mutate: exportToIcs } = useMutation(api.Sessions.exportToIcsFile, {
    onSuccess: async (data) => {
      if (data) {
        setStateAlert({ open: true, message: `${t('event_added_successfully')}` });
        const dateStartAdjusted = adjustDateWithTimezone(data.start);
        data.start = dateStartAdjusted;
        createEvent(data, (error, value) => {
          const blob = new Blob([value], { type: 'text/plain;charset=utf-8' });
          saveAs(blob, 'event-schedule.ics');
        });
        return;
      }
    },
  });

  const { mutate: deleteSession } = useMutation(api.Sessions.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(['get-programs']);
      queryClient.invalidateQueries(['get-session']);
      setStateAlert({ open: true, message: `${t(MESSAGE_SUCCESS_CHANGES)}` });
    },
  });

  const submit = (data) => {
    const payload = {
      comment: data.comment || '',
      done: data.done,
      name: data.name,
      subtitle: session.subtitle || '',
      description: data.description || '',
      duration: data.duration || 0,
      ...(data.done ? { date_done: dayjs().format('YYYY-MM-DD') } : {}),
    };
    if (data.date) {
      payload.date = DateTime.fromISO(data.date).toUTC().toSQL();
    } else {
      payload.date = null;
    }

    if (data.calBooking) {
      payload.calBooking = data.calBooking;
    }

    return mutation.mutate({ id: session.id, payload });
  };

  return {
    formState,
    state: { form: { control } },
    actions: { submit, exportToIcs, deleteSession },
  };
};

export default useSession;
