import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from 'ui/components/system/Box';
import GoogleCalendarButton from 'ui/components/GoogleCalendarButton/GoogleCalendarButton';
import OutlookCalendarButton from 'ui/components/OutlookCalendarButton/OutlookCalendarButton';
import { useCalAccessToken } from 'hooks/queries';
import { CalProvider, SelectedCalendarsSettings } from '@calcom/atoms';
import { redirectAuthUrlGoogle, redirectAuthUrlOutlook, getAllCalendars } from 'utils/cal-com';

const CalendarConnection = () => {
  const { t, i18n } = useTranslation();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isOutlookLoading, setIsOutlookLoading] = useState(false);
  const [isCheckIfCalendarLoading, setIsCheckIfCalendarLoading] = useState(true);
  const [thereAreCalendarsConnected, setThereAreCalendarsConnected] = useState(false);
  const [queryParamsAccessToken, setQueryParamsAccessToken] = useState({ disableCreateUser: true });
  const calendarsConnectedRef = useRef(thereAreCalendarsConnected);

  const { data: calAccessTokenData, isLoading: isLoadingCalAccessToken } = useCalAccessToken(queryParamsAccessToken);

  const calAccessToken = calAccessTokenData?.accessToken;

  const calAccessTokenRef = useRef(calAccessToken);

  const onClickGoogle = async () => {
    setIsGoogleLoading(true);
    setQueryParamsAccessToken({});
  };

  const onClickOutlook = async () => {
    setIsOutlookLoading(true);
    setQueryParamsAccessToken({});
  };

  useEffect(() => {
    calendarsConnectedRef.current = thereAreCalendarsConnected;
  }, [thereAreCalendarsConnected]);

  useEffect(() => {
    calAccessTokenRef.current = calAccessToken;
  }, [calAccessToken]);

  useEffect(() => {
    if (isGoogleLoading && calAccessToken) {
      redirectAuthUrlGoogle(calAccessToken, '/settings#calendar');
    }
    if (isOutlookLoading && calAccessToken) {
      redirectAuthUrlOutlook(calAccessToken, '/settings#calendar');
    }
  }, [isGoogleLoading, calAccessToken]);

  useEffect(() => {
    if (isLoadingCalAccessToken) return;

    const fetchCalendars = async () => {
      const calendars = await getAllCalendars(calAccessToken);

      setIsCheckIfCalendarLoading(false);
      setThereAreCalendarsConnected(!!calendars?.data?.connectedCalendars?.length);
    };

    if (calAccessToken) {
      fetchCalendars();
    } else if (calAccessToken === '') {
      setThereAreCalendarsConnected(false);
      setIsCheckIfCalendarLoading(false);
    }
  }, [calAccessToken, isLoadingCalAccessToken]);

  useEffect(() => {
    const interval = setInterval(async () => {
      if (calendarsConnectedRef.current) {
        const calendars = await getAllCalendars(calAccessTokenRef.current);
        setThereAreCalendarsConnected(!!calendars?.data?.connectedCalendars?.length);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const language = i18n.language ? i18n.language.split('-')[0] : 'fr';

  if (isCheckIfCalendarLoading) return <p>Loading...</p>;

  return (
    <Box sx={{ marginTop: '32px' }}>
      {!thereAreCalendarsConnected && (
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <OutlookCalendarButton
            loading={isOutlookLoading}
            disabled={isOutlookLoading}
            label={t('profile_calendar_connection.buttons.outlook')}
            onClick={onClickOutlook}
          />
          <GoogleCalendarButton
            loading={isGoogleLoading}
            disabled={isGoogleLoading}
            label={t('profile_calendar_connection.buttons.google')}
            onClick={onClickGoogle}
          />
        </Box>
      )}

      {thereAreCalendarsConnected && (
        <Box
          sx={{
            '.border-subtle.mx-5': { margin: '0 !important' },
            '.border-subtle button[aria-haspopup="menu"]': { display: 'none' },
          }}>
          {!!calAccessToken && !isGoogleLoading && (
            <CalProvider
              language={language}
              accessToken={calAccessToken}
              clientId={process.env.REACT_APP_CAL_OAUTH_CLIENT_ID ?? ''}
              options={{
                apiUrl: process.env.REACT_APP_CAL_API_URL ?? '',
                refreshUrl: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_CAL_REFRESH_URL}` ?? '',
              }}>
              <SelectedCalendarsSettings />
            </CalProvider>
          )}
        </Box>
      )}
    </Box>
  );
};

export default CalendarConnection;
