import { get, put, post, del } from './request';

class Sessions {
  static get(id) {
    return get(`/sessions/${id}`).then((res) => res.data);
  }

  static edit({ id, payload }) {
    return put(`/sessions/${id}`, payload).then((res) => res.data);
  }

  static create(payload) {
    return post('/sessions', payload).then((res) => res.data);
  }

  static exportToIcsFile({ id }) {
    return post('/sessions/exportToIcsFile', { id }).then((res) => res.data);
  }

  static delete(id) {
    return del(`/sessions/${id}`).then((res) => res.data);
  }

  static calSessionInfo(id) {
    return get(`/sessions/cal-com/info/${id}`).then((res) => res.data);
  }
}

export default Sessions;
