const { REACT_APP_CAL_API_URL } = process.env;

const extractOrigin = (url) => {
  const match = url.match(/origin%3D(.*?)(&|%26redir%3D)/);
  return match ? match[1] : null;
};

const redirectAuthUrlGoogle = async (accessToken, addToOrigin) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetch(`${REACT_APP_CAL_API_URL}/gcal/oauth/auth-url`, options)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data?.authUrl) {
        const origin = extractOrigin(response.data.authUrl);
        window.location.href = response.data.authUrl.replace(origin, `${origin}${encodeURIComponent(addToOrigin)}`);
      } else {
        // ERROR
      }
    })
    .catch((err) => console.error(err));
};

const redirectAuthUrlOutlook = async (accessToken, addToOrigin) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetch(`${REACT_APP_CAL_API_URL}/calendars/office365/connect`, options)
    .then((response) => response.json())
    .then((response) => {
      if (response?.data?.authUrl) {
        const origin = extractOrigin(response.data.authUrl);
        window.location.href = response.data.authUrl.replace(origin, `${origin}${encodeURIComponent(addToOrigin)}`);
      } else {
        // ERROR
      }
    })
    .catch((err) => console.error(err));
};

const getAllCalendars = (accessToken) => {
  const options = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  return fetch('https://api.cal.com/v2/calendars', options)
    .then((response) => response.json())
    .catch((err) => console.error(err));
};

export { redirectAuthUrlGoogle, redirectAuthUrlOutlook, getAllCalendars };
