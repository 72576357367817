import React, { useEffect } from 'react';
import { useMeQuery } from 'hooks/queries';
import Router from 'ui/router/Router';
import '@calcom/atoms/globals.min.css';

function App() {
  useEffect(() => {
    localStorage.removeItem('FILTER_CHOICE_INTERNAL');
  }, []);

  const hasToken = !!localStorage.getItem('REACT_TOKEN_AUTH');
  const { isLoading } = useMeQuery({
    enabled: hasToken,
    retry: false,
  });
  if (hasToken && isLoading) return <p>Try loading user...</p>;
  return <Router />;
}

export default App;
